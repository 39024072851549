/* eslint-disable prettier/prettier */
export default {
    'Taurus Merchant': 'आधिकारिक चिप्स विक्रेता',
    'Hello World': 'नमस्ते दुनिया',
    homeTitle: 'Home Title',
    vipRecharge: "आधिकारिक चिप्स विक्रेता",
    step1_hl: 'छूट पाएं',
    step1_detail: ', रीचार्ज करते समय, विवरण हेतु कृपया एफि‍लिएट से परामर्श करें।.',
    step2_hl: 'रिचार्ज की कोई सीमा नहीं',
    step2_detail: ', रिचार्ज राशि की कोई सीमा नहीं, समय बचता नहीं।.',
    step3_hl: 'तत्काल रिचार्ज',
    step3_detail: ', आधिकारिक गारंटी। एक के बाद एक 24h x 7 सपोर्ट सर्विस।',
    chooseTip: "कृपया चिप्स खरीदने के लिए एक चिप्स विक्रेता चुनें",
    report: "रिपोर्ट",
    noResponse: "लंबे समय से कोई प्रतिक्रिया नहीं",
    notReceived: "मैंने मनी ट्रांसफर किया, लेकिन चिप्स का रिचार्ज नहीं मिला।.",
    fraud: "धोखाधड़ी की जानकारी.",
    uploadTip: "कृपया अपना चित्र प्रमाण अपलोड करें:",
    submit: "सबमिट करें",
    reportSuccess: "रिपोर्ट सफल हो",
    error: "नेटवर्क एर",
    noWhatsApp: "वत्सप्प इंस्टॉल नहीं ",
    whatsMessage: "तीन पत्‍ती मास्टर(TeenPatti Master) में मेरी यह आईडी XXXXXXX है।. मुझे आपसे चिप्स खरीदने हैं।. मुझे कितनी छूट मिल सकती है?",
    whatsappMessage:"हाय, मैं तीनपत्ती मास्टर गेम आईडी XXXXXXX, गेम का उपनाम @{name}। मैं आपसे चिप्स खरीदना चाहता हूं। मुझे कितना डिस्काउंट मिल सकता है?",
    totalOrders: "कुल आर्डर:",
    chipsTransaction: "चिप्स का ट्रांजेक्‍शन:",
    Tips:'टिप्स',
    'If you do not receive the money,':'अगर आपको पैसे नहीं मिलते हैं',
    'Master will refund the full amount.':'तो वुंगो पूरी राशि वापस कर देगा।',

    'Recharge now':'अभी रिचार्ज करें',
    'Recharge':'रिचार्ज करें',
}
