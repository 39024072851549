/* eslint-disable prettier/prettier */
export default {
    "Taurus Merchant": "Official Chips Seller",
    "Hello World": "Hello World",
    homeTitle: "Home Title",
    vipRecharge: "Official Chips Seller",
    step1_hl: 'Get discounts',
    step1_detail: ' when doing recharge, please consult agents below for details.',
    step2_hl: 'No Recharge Limit',
    step2_detail: ', no limit to the amount of \nrecharge, time-saving.',
    step3_hl: 'Instant Recharge',
    step3_detail: ', Official guarantee. \nOne on one support service, 24h x7',
    chooseTip: "Please choose one chips seller to buy chips",
    report: "Report",
    contact: "Contact",
    cancel: "Cancel",
    noResponse: "Long time no response",
    notReceived: "I transferred money, but haven’t received recharge chips.",
    fraud: "Fraud information.",
    uploadTip: "Please upload your picture proof:",
    submit: "Submit",
    reportSuccess: "Report successfully",
    error: "Network Error",
    noWhatsApp: "WhatsApp not installed",
    whatsMessage: "Hi, this is my ID XXXXXXX in TeenPatti Master. I want to buy chips from you. How much discount can I get ?",
    whatsappMessage:'Hi, my TeenPatti Master game ID XXXXXXX, game nickname @{name}. I want to buy chips from you. How much discount can I get ?',
    totalOrders: "Total Orders:",
    chipsTransaction: "Chips Transaction:",
    Tips:'Tips',
    'If you do not receive the money,': 'If you do not receive the money,',
    'Master will refund the full amount.': 'Master will refund the full amount.',
    'Recharge now': 'Recharge now',
    'Recharge':'Recharge',
    
};
