class Logger {
    error() {}

    info(key, value) {
        let v = Object.assign({}, value||{})
        console.log('try to report event:', key, v);
        if (analytics) {
            analytics.logEvent(key, v);
            console.log(key, v);
            return;
        }
    }
}

export default new Logger();
