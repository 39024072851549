import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './en';
import hiLocale from './hindi';
import Locale from '@/utils/locale';

const messages = {
    en: {
        ...enLocale
    },
    hi: {
        ...hiLocale
    },
};

class I18n extends VueI18n {
    constructor(options) {
        super(options);
    }
}

Vue.use(I18n);

const i18n = new I18n({
    locale: 'en',
    messages,
    silentTranslationWarn: true,
});

i18n.locale = Locale.getLocale();

const translate = key => {
    if (!key) {
        return '';
    }
    return i18n.t(key);
};
i18n.translate = translate;

export default i18n;
